import React, { useEffect, useState } from 'react';
import { Box, Grid, Stack, Typography, colors } from '@mui/material';
import Animate from "./Animate";
import MPaper from './MPaper';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../firebase';

// Import images directly
import totalBookImage from '../../assets/images/users.png';
import cancelImage from '../../assets/images/search.png';

const SummaryGrid = () => {
  const [summaryData, setSummaryData] = useState([
    { title: "Total Users", value: "Loading...", image: totalBookImage },
    { title: "Searches", value: "Loading...", image: cancelImage }
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const usersQuery = query(collection(db, 'user_entries'));
        const searchesQuery = query(collection(db, 'user_searches'));

        const [usersSnapshot, searchesSnapshot] = await Promise.all([getDocs(usersQuery), getDocs(searchesQuery)]);

        const totalUsers = usersSnapshot.docs
          .filter(doc => new Date(doc.data().date).toDateString() === today.toDateString())
          .reduce((acc, doc) => acc + doc.data().entryCount, 0);

        const totalSearches = searchesSnapshot.docs
          .filter(doc => new Date(doc.data().date).toDateString() === today.toDateString())
          .reduce((acc, doc) => acc + doc.data().searchCount, 0);

        setSummaryData([
          { title: "Total Users", value: totalUsers, image: totalBookImage },
          { title: "Searches", value: totalSearches, image: cancelImage }
        ]);
      } catch (error) {
        console.error("Error fetching summary data: ", error);
      }
    };

    fetchData();
  }, []);

  return (
    <Grid container spacing={3}>
      {summaryData.map((summary, index) => (
        <Grid key={index} item xs={12} lg={4}>
          <Animate type="fade" delay={(index + 1) / 3}>
            <MPaper>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Stack spacing={1}>
                  <Typography variant="h4" fontWeight="bold">
                    {summary.value}
                  </Typography>
                  <Typography variant="body2" fontWeight="bold" color={colors.grey[600]}>
                    {summary.title}
                  </Typography>
                </Stack>
                <Box sx={{
                  height: "100px",
                  width: "100px",
                  "& img": { width: "100%" }
                }}>
                  <img src={summary.image} alt="summary" />
                </Box>
              </Stack>
            </MPaper>
          </Animate>
        </Grid>
      ))}
    </Grid>
  );
};

export default SummaryGrid;
