import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Box, Paper, Stack, Typography, colors } from '@mui/material';
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../firebase';

const TotalIncome = () => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: []
  });
  const [totalUsers, setTotalUsers] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const now = new Date();
      const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
      const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    
      const startOfMonthStr = startOfMonth.toISOString().split('T')[0];
      const endOfMonthStr = endOfMonth.toISOString().split('T')[0];
    
      const usersQuery = query(collection(db, 'user_entries'), where('date', '>=', startOfMonthStr), where('date', '<=', endOfMonthStr));
      const querySnapshot = await getDocs(usersQuery);
      const data = querySnapshot.docs.map(doc => doc.data());
    
      console.log("Fetched data: ", data); // Debug log
    
      const dates = data.map(d => d.date);
      const totalUsersCount = data.reduce((acc, d) => acc + d.entryCount, 0);
    
      setTotalUsers(totalUsersCount);
    
      setChartData({
        labels: dates,
        datasets: [
          {
            label: "Users",
            data: data.map(d => d.entryCount),
            borderColor: colors.green[600],
            tension: 0.5
          }
        ]
      });
    };
    

    fetchData();
  }, []);

  return (
    <Paper elevation={0} sx={{
      p: 3,
      background: "linear-gradient(135deg, rgba(91, 228, 155, 0.2), rgba(0, 167, 111, 0.2)) rgb(255, 255, 255)",
      color: colors.green[800],
      height: "100%"
    }}>
      <Stack spacing={3}>
        <Stack direction="row" justifyContent="space-between">
          <Stack spacing={2}>
            <Typography variant="body2" fontWeight="bold">Total Users this month</Typography>
            <Typography variant="h4" fontWeight="bold">{totalUsers}</Typography>
          </Stack>
          <Stack>
            <Stack direction="row" justifyContent="flex-end" spacing={2}>
              <TrendingUpOutlinedIcon fontSize="small" />
              <Typography variant="body2" fontWeight="bold">
               
              </Typography>
            </Stack>
            <Typography variant="subtitle2" fontWeight={400}>
              than last month
            </Typography>
          </Stack>
        </Stack>
        <Box>
          <Line
            data={chartData}
            height="100px"
            options={{
              responsive: true,
              maintainAspectRatio: true,
              scales: {
                x: { display: false },
                y: { display: false }
              },
              elements: { point: { radius: 0 } },
              plugins: { legend: { display: false } }
            }}
          />
        </Box>
      </Stack>
    </Paper>
  );
};

export default TotalIncome;
