import React, { useState, useEffect } from 'react';
import { 
  Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
  Paper, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle,
  IconButton, Tooltip, Snackbar, Alert, Select, MenuItem
} from '@mui/material';
import { Edit, Delete, LockReset, PersonAdd } from '@mui/icons-material';
import { collection, getDocs, doc, setDoc, deleteDoc, updateDoc } from 'firebase/firestore';
import { createUserWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { db, auth } from '../firebase'; // Ensure this path is correct

const Users = () => {
  const [users, setUsers] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogType, setDialogType] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [newUser, setNewUser] = useState({ email: '', password: '', role: 'guest' });
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    const usersCollection = collection(db, 'role');
    const userSnapshot = await getDocs(usersCollection);
    const userList = userSnapshot.docs.map(doc => ({ uid: doc.id, ...doc.data() }));
    setUsers(userList);
  };

  const handleOpenDialog = (type, user = null) => {
    setDialogType(type);
    setSelectedUser(user);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedUser(null);
    setNewUser({ email: '', password: '', role: 'guest' });
  };

  const handleCreateUser = async () => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, newUser.email, newUser.password);
      await setDoc(doc(db, 'role', userCredential.user.uid), { role: newUser.role });
      setSnackbar({ open: true, message: 'User created successfully', severity: 'success' });
      fetchUsers();
      handleCloseDialog();
    } catch (error) {
      setSnackbar({ open: true, message: `Error: ${error.message}`, severity: 'error' });
    }
  };

  const handleUpdateRole = async () => {
    try {
      await updateDoc(doc(db, 'role', selectedUser.uid), { role: selectedUser.role });
      setSnackbar({ open: true, message: 'User role updated successfully', severity: 'success' });
      fetchUsers();
      handleCloseDialog();
    } catch (error) {
      setSnackbar({ open: true, message: `Error: ${error.message}`, severity: 'error' });
    }
  };

  const handleDeleteUser = async () => {
    try {
      await deleteDoc(doc(db, 'role', selectedUser.uid));
      // Note: This doesn't delete the user from Firebase Auth, only from your 'role' collection
      setSnackbar({ open: true, message: 'User deleted successfully', severity: 'success' });
      fetchUsers();
      handleCloseDialog();
    } catch (error) {
      setSnackbar({ open: true, message: `Error: ${error.message}`, severity: 'error' });
    }
  };

  const handleResetPassword = async () => {
    try {
      await sendPasswordResetEmail(auth, selectedUser.email);
      setSnackbar({ open: true, message: 'Password reset email sent', severity: 'success' });
      handleCloseDialog();
    } catch (error) {
      setSnackbar({ open: true, message: `Error: ${error.message}`, severity: 'error' });
    }
  };

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        User Management
      </Typography>
      <Button 
        variant="contained" 
        color="primary" 
        startIcon={<PersonAdd />} 
        onClick={() => handleOpenDialog('create')}
        sx={{ mb: 2 }}
      >
        Add New User
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>UID</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.uid}>
                <TableCell>{user.uid}</TableCell>
                <TableCell>{user.role}</TableCell>
                <TableCell>
                  <Tooltip title="Edit Role">
                    <IconButton onClick={() => handleOpenDialog('edit', user)}>
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Reset Password">
                    <IconButton onClick={() => handleOpenDialog('reset', user)}>
                      <LockReset />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete User">
                    <IconButton onClick={() => handleOpenDialog('delete', user)}>
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          {dialogType === 'create' ? 'Create New User' : 
           dialogType === 'edit' ? 'Edit User Role' :
           dialogType === 'reset' ? 'Reset User Password' : 'Delete User'}
        </DialogTitle>
        <DialogContent>
          {dialogType === 'create' && (
            <>
              <TextField
                autoFocus
                margin="dense"
                label="Email Address"
                type="email"
                fullWidth
                value={newUser.email}
                onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
              />
              <TextField
                margin="dense"
                label="Password"
                type="password"
                fullWidth
                value={newUser.password}
                onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
              />
              <Select
                fullWidth
                value={newUser.role}
                onChange={(e) => setNewUser({ ...newUser, role: e.target.value })}
                sx={{ mt: 2 }}
              >
                <MenuItem value="guest">Guest</MenuItem>
                <MenuItem value="admin">Admin</MenuItem>
              </Select>
            </>
          )}
          {dialogType === 'edit' && (
            <Select
              fullWidth
              value={selectedUser?.role || ''}
              onChange={(e) => setSelectedUser({ ...selectedUser, role: e.target.value })}
            >
              <MenuItem value="guest">Guest</MenuItem>
              <MenuItem value="admin">Admin</MenuItem>
            </Select>
          )}
          {dialogType === 'reset' && (
            <Typography>Are you sure you want to reset the password for this user?</Typography>
          )}
          {dialogType === 'delete' && (
            <Typography>Are you sure you want to delete this user?</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          {dialogType === 'create' && <Button onClick={handleCreateUser}>Create</Button>}
          {dialogType === 'edit' && <Button onClick={handleUpdateRole}>Update</Button>}
          {dialogType === 'reset' && <Button onClick={handleResetPassword}>Reset</Button>}
          {dialogType === 'delete' && <Button onClick={handleDeleteUser}>Delete</Button>}
        </DialogActions>
      </Dialog>

      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Users;