import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  List,
  TextField,
  Button,
  Grid,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Container,
  Card,
  CardContent,
  CardActions,
  LinearProgress,
} from "@mui/material";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { db, storage } from "../firebase";
import { doc, setDoc, collection, onSnapshot, query, deleteDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

const categories = [
  { key: 'reports', label: 'Reports' },
  { key: 'guides', label: 'Guides' },
  { key: 'manuals', label: 'Manuals' },
  // add more categories as needed
];

const DocumentUpload = () => {
  const [documents, setDocuments] = useState([]);
  const [newDocument, setNewDocument] = useState({
    title: "",
    description: "",
    file: null,
    fileUrl: "",
    category: "",
  });
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [editDocumentId, setEditDocumentId] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [documentToDelete, setDocumentToDelete] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [openProgressDialog, setOpenProgressDialog] = useState(false);
  const [uploadCompleted, setUploadCompleted] = useState(false);

  useEffect(() => {
    const documentsRef = query(collection(db, "Documents"));
    const unsubscribe = onSnapshot(documentsRef, (querySnapshot) => {
      const documentsData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setDocuments(documentsData);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewDocument((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setNewDocument((prevState) => ({
      ...prevState,
      file,
      fileUrl: "", // clear fileUrl when a new file is added
    }));
  };

  const uploadFileAndGetUrl = (file) => {
    return new Promise((resolve, reject) => {
      const storageRef = ref(storage, `documents/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        },
        (error) => {
          console.error("Upload error:", error);
          reject(error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL);
        }
      );
    });
  };

  const handleAddOrEditDocument = async () => {
    let fileUrl = newDocument.fileUrl;
  
    setOpenProgressDialog(true);
    if (newDocument.file) {
      fileUrl = await uploadFileAndGetUrl(newDocument.file);
    }
  
    const newDocumentData = {
      title: newDocument.title,
      description: newDocument.description,
      fileUrl,
      category: newDocument.category,
      date: new Date().toISOString().slice(0, 10), // add the current date
    };
  
    try {
      if (isEditing) {
        await setDoc(doc(db, "Documents", editDocumentId), newDocumentData);
        setDocuments((prevDocuments) =>
          prevDocuments.map((doc) =>
            doc.id === editDocumentId ? { ...newDocumentData, id: editDocumentId } : doc
          )
        );
      } else {
        const newDocRef = doc(collection(db, "Documents"));
        await setDoc(newDocRef, newDocumentData);
        setDocuments((prevDocuments) => [...prevDocuments, { ...newDocumentData, id: newDocRef.id }]);
      }
      setUploadCompleted(true);
    } catch (error) {
      console.error("Error adding/editing document:", error);
    }
  
    setNewDocument({ title: "", description: "", file: null, fileUrl: "", category: "" });
    setIsEditing(false);
    setEditDocumentId(null);
    setOpenProgressDialog(false);
    setUploadProgress(0);
  };
  

  const handleEdit = (doc) => {
    setNewDocument({
      title: doc.title,
      description: doc.description,
      file: null,
      fileUrl: doc.fileUrl,
      category: doc.category,
    });
    setIsEditing(true);
    setEditDocumentId(doc.id);
  };

  const handleDelete = async () => {
    try {
      await deleteDoc(doc(db, "Documents", documentToDelete));
      setDocuments((prevDocuments) => prevDocuments.filter((doc) => doc.id !== documentToDelete));
    } catch (error) {
      console.error("Error deleting document:", error);
    }
    setOpenDeleteDialog(false);
    setDocumentToDelete(null);
  };

  const openDeleteConfirmation = (id) => {
    setOpenDeleteDialog(true);
    setDocumentToDelete(id);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setDocumentToDelete(null);
  };

  const handleCloseProgressDialog = () => {
    setOpenProgressDialog(false);
    setUploadCompleted(false);
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 5 }}>
      <Typography
        variant="h4"
        component="div"
        gutterBottom
        sx={{
          color: "#333333",
          fontWeight: "bold",
          borderBottom: "1px solid",
          paddingBottom: "0.1rem",
          textTransform: "uppercase",
          letterSpacing: "0.1rem",
          textAlign: "center",
          borderRadius: 3,
          border: 0,
          boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .1)",
          padding: "0 30px",
        }}
      >
        Community Documents
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography
                variant="h5"
                component="div"
                gutterBottom
                sx={{
                  color: "#333333",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Post a Document
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Title"
                    variant="outlined"
                    name="title"
                    value={newDocument.title}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Description"
                    variant="outlined"
                    name="description"
                    value={newDocument.description}
                    onChange={handleChange}
                    multiline
                    rows={4}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="category-label">Category</InputLabel>
                    <Select
                      labelId="category-label"
                      id="category"
                      name="category"
                      value={newDocument.category}
                      onChange={handleChange}
                      label="Category"
                    >
                      {categories.map((category) => (
                        <MenuItem key={category.key} value={category.key}>
                          {category.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    component="label"
                    fullWidth
                    startIcon={<PhotoCameraIcon />}
                  >
                    Upload Document
                    <input
                      type="file"
                      hidden
                      onChange={handleFileChange}
                    />
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  {newDocument.file && (
                    <Box sx={{ mt: 2 }}>
                      <Typography variant="body2" color="textSecondary">
                        Selected File: {newDocument.file.name}
                      </Typography>
                    </Box>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    onClick={handleAddOrEditDocument}
                    fullWidth
                    color="primary"
                  >
                    {isEditing ? "Edit Document" : "Post Document"}
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            variant="h5"
            component="div"
            gutterBottom
            sx={{
              color: "#333333",
              fontWeight: "bold",
              textAlign: "center",
              mb: 2,
            }}
          >
            Uploaded Documents
          </Typography>

          {loading ? (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
              <CircularProgress />
            </Box>
          ) : documents.length > 0 ? (
            <List component="nav" aria-label="uploaded documents">
              {documents.map((doc) => (
                <Accordion
                  key={doc.id}
                  sx={{
                    marginBottom: 2,
                    borderRadius: 2,
                    borderColor: "secondary.main",
                    borderWidth: 1,
                    borderStyle: "solid",
                  }}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">{doc.title}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body2" color="textPrimary" sx={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                      Description: {doc.description}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Date: {doc.date}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Category: {doc.category}
                    </Typography>
                    <Box sx={{ display: "flex", justifyContent: "flex-start", mt: 2 }}>
                      <a href={doc.fileUrl} target="_blank" rel="noopener noreferrer">
                        {doc.fileUrl.split('/').pop()}
                      </a>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                      <IconButton color="primary" onClick={() => handleEdit(doc)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton color="secondary" onClick={() => openDeleteConfirmation(doc.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              ))}
            </List>
          ) : (
            <Typography variant="body1" component="div" sx={{ p: 2 }}>
              No documents available.
            </Typography>
          )}
        </Grid>
      </Grid>
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
      >
        <DialogTitle>{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this document? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openProgressDialog}
        onClose={handleCloseProgressDialog}
      >
        <DialogTitle>
          {uploadCompleted ? "Upload Completed" : "Uploading..."}
        </DialogTitle>
        <DialogContent>
          {uploadCompleted ? (
            <DialogContentText>
              Document upload was successful!
            </DialogContentText>
          ) : (
            <>
              <DialogContentText>
                Your document is being uploaded. Please wait...
              </DialogContentText>
              <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                <Box sx={{ width: "100%", mr: 1 }}>
                  <LinearProgress variant="determinate" value={uploadProgress} />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                  <Typography variant="body2" color="textSecondary">{`${Math.round(
                    uploadProgress
                  )}%`}</Typography>
                </Box>
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions>
          {uploadCompleted && (
            <Button onClick={handleCloseProgressDialog} color="primary">
              Close
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default DocumentUpload;
