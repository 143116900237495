import React, { useEffect, useState } from 'react';
import MPaper from './MPaper';
import { Box, Button, Stack, Typography, colors } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';

const StatisticData = () => {
  const [chartData, setChartData] = useState({
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: []
  });

  const [showTotalUsers, setShowTotalUsers] = useState(true);
  const [showTotalSearches, setShowTotalSearches] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch user entries data
        const userEntriesSnapshot = await getDocs(collection(db, 'user_entries'));
        const userEntriesData = userEntriesSnapshot.docs.map(doc => doc.data());

        // Fetch user searches data
        const userSearchesSnapshot = await getDocs(collection(db, 'user_searches'));
        const userSearchesData = userSearchesSnapshot.docs.map(doc => doc.data());

        // Aggregate data for each month
        const totalUsers = new Array(12).fill(0);
        const totalSearches = new Array(12).fill(0);

        userEntriesData.forEach(entry => {
          const month = new Date(entry.date).getMonth();
          totalUsers[month] += entry.entryCount;
        });

        userSearchesData.forEach(search => {
          const month = new Date(search.date).getMonth();
          totalSearches[month] += search.searchCount;
        });

        setChartData({
          labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
          datasets: [
            {
              label: 'Total Users',
              data: totalUsers,
              backgroundColor: colors.blue[600],
              hidden: !showTotalUsers
            },
            {
              label: 'Total Searches',
              data: totalSearches,
              backgroundColor: colors.green[600],
              hidden: !showTotalSearches
            }
          ]
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [showTotalUsers, showTotalSearches]);

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: { display: false },
        stacked: true
      },
      y: { stacked: true }
    },
    plugins: {
      legend: { display: false },
      title: { display: false }
    },
    elements: {
      bar: {
        borderRadius: 10
      }
    }
  };

  return (
    <MPaper title="Statistic">
      <Stack spacing={4}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="body2">
            {/* Add any additional text here */}
          </Typography>
          <Stack direction="row" spacing={3} alignItems="center">
            <Button variant="contained" onClick={() => setShowTotalUsers(!showTotalUsers)}>
              {showTotalUsers ? "Hide Total Users" : "Show Total Users"}
            </Button>
            <Button variant="contained" onClick={() => setShowTotalSearches(!showTotalSearches)}>
              {showTotalSearches ? "Hide Total Searches" : "Show Total Searches"}
            </Button>
          </Stack>
        </Stack>
        <Box>
          <Bar options={chartOptions} data={chartData} height="300px" />
        </Box>
      </Stack>
    </MPaper>
  );
};

export default StatisticData;
