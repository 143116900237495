import React from 'react';
import styled from 'styled-components';
import notFoundImage from '../assets/images/404-image.png';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
    const navigate = useNavigate();

    const handleGoHome = () => {
      navigate('/dashboard'); 
    };
    
  return (
    <StyledNotFound>
      <main>
        <div className="container">
          <div className="row">
            <div className="col-md-6 align-self-center">
            <div style={{ marginTop: '20rem'  }}>
        <img
          src={notFoundImage}
          alt="404 Not Found"
          className="not-found-image"
          style={{ maxWidth: '300px', margin: '0 auto', alignItems: 'center'  }}
        />
      </div>
            </div>
            <div className="col-md-6 align-self-center">
              <h1>404</h1>
              <h2>UH OH! You're lost.</h2>
              <p>The page you are looking for does not exist.
                How you got here is a mystery. But you can click the button below
                to go back to the homepage.
              </p>
              <button className="btn green" onClick={handleGoHome}>HOME</button>
            </div>
          </div>
        </div>
      </main>
    </StyledNotFound>
  );
};

const StyledNotFound = styled.div`
  @import url('https://fonts.googleapis.com/css?family=Nunito+Sans');

  --blue: #0e0620;
  --white: #fff;
  --green: #2ccf6d;

  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Nunito Sans";
  color: var(--blue);
  font-size: 1em;

  button {
    font-family: "Nunito Sans";
  }

  .not-found-image {
    width: 100%;
    max-width: 600px;
    height: auto;
  }

  h1 {
    font-size: 7.5em;
    margin: 15px 0px;
    font-weight: bold;
  }

  h2 {
    font-weight: bold;
  }

  .btn {
    z-index: 1;
    overflow: hidden;
    background: transparent;
    position: relative;
    padding: 8px 50px;
    border-radius: 30px;
    cursor: pointer;
    font-size: 1em;
    letter-spacing: 2px;
    transition: 0.2s ease;
    font-weight: bold;
    margin: 5px 0px;

    &.green {
      border: 4px solid var(--green);
      color: var(--blue);

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 0%;
        height: 100%;
        background: var(--green);
        z-index: -1;
        transition: 0.2s ease;
      }

      &:hover {
        color: var(--white);
        background: var(--green);
        transition: 0.2s ease;

        &:before {
          width: 100%;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .container {
      margin-top: 70px;
      margin-bottom: 70px;
    }
  }
`;

export default NotFound;